<template>
  <div></div>
</template>

<script>
import {Service} from "./_resource";

export default {
  mounted() {
    Service.issueCbtUrl(this.$route.params.examineeId).then(data => {
      location.replace(data.url);
    });
  }
}
</script>
